import consumer from "./consumer"

consumer.subscriptions.create("AssistantRunStatusChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel
    const chatIndicator = document.getElementById("chat-indicator");
    const submitButton = document.getElementById("submit_button");
    const progressButton = document.getElementById("progress_button");

    if (data.status === "starting") {
      console.log("starting")
      chatIndicator.style.visibility = 'visible';
      submitButton.classList.add('hidden');
      progressButton.classList.remove('hidden');
    } else if (data.status === "done") {
      console.log("stopping")
      chatIndicator.style.visibility = 'hidden';
      submitButton.classList.remove('hidden');
      progressButton.classList.add('hidden');
    }
  }
});

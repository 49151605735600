import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="off-canvas-menu"
export default class extends Controller {
  static targets = ["menu", "backdrop", "nav", "closeBtn", "openBtn"]

  connect() {
  }

  openMenu(){
    this.menuTarget.classList.toggle('hidden');

    setTimeout(() => {
      this.navTarget.classList.remove('-translate-x-full');
      this.navTarget.classList.add('translate-x-0');
  
      this.backdropTarget.classList.remove('opacity-0');
      this.backdropTarget.classList.add('opacity-100');
  
      this.closeBtnTarget.classList.remove('opacity-0');
      this.closeBtnTarget.classList.add('opacity-100');
      
    }, 1);
  }

  closeMenu(){
    this.navTarget.classList.remove('translate-x-0');
    this.navTarget.classList.add('-translate-x-full');

    this.backdropTarget.classList.remove('opacity-100');
    this.backdropTarget.classList.add('opacity-0');

    this.closeBtnTarget.classList.remove('opacity-100');
    this.closeBtnTarget.classList.add('opacity-0');

    setTimeout(() => {
      this.menuTarget.classList.toggle('hidden');
    }, 301);
  }

}

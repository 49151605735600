import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

    connect() {
        // Check if iconStart and tabStart are present before calling sendCount
        const iconStart = this.element.dataset.iconStart ? parseInt(this.element.dataset.iconStart, 10) : null;
        const tabStart = this.element.dataset.tabStart ? parseInt(this.element.dataset.tabStart, 10) : null;

        if (iconStart !== null && tabStart !== null) {
            this.sendCount(iconStart, tabStart);
        }
    }

    sendCount(icon, tab) {
        // Call the TurboNativeBridge function with the dynamic values
        window.TurboNativeBridge.setNotificationCount(icon, tab);
    }

    sendCountOnClick() {
        const icon = this.element.dataset.icon ? parseInt(this.element.dataset.icon, 10) : null;
        const tab = this.element.dataset.tab ? parseInt(this.element.dataset.tab, 10) : null;

        if (icon !== null && tab !== null) {
            // Call the TurboNativeBridge function with the dynamic values
            window.TurboNativeBridge.setNotificationCount(icon, tab);
        }
    }
}

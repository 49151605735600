import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["submitBtn"]
    connect() {
        const calendarModal = document.getElementsByClassName("calendar-modal")[0]
        if (calendarModal && !calendarModal.classList.contains('hidden')) {
            document.body.style.overflow = "hidden";
        }
       
    }

    removeBodyStyles() {
        document.body.style.overflow = null;
        document.body.style.paddingRight = null;
    }

}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["panel", "contentArea", "toggleButtonInside", "toggleButtonOutside"];

    connect() {
        this.isSidebarVisible = true;
        this.panelTarget.classList.add('sidebar-transitioning');
        this.makeCalendarSticky();
    }
    toggle() {
        const panel = this.panelTarget;
        const contentArea = this.contentAreaTarget;

        if (this.isSidebarVisible) {
            panel.setAttribute('hidden', true);  // Hide using the hidden attribute
            panel.style.height = '0px';  // Collapse the height
            contentArea.classList.replace("lg:col-span-8", "lg:col-span-12");
            this.toggleButtonInsideTarget.style.display = 'none';
            this.toggleButtonOutsideTarget.style.display = 'block';
            this.makeCalendarStatic();
        } else {
            panel.removeAttribute('hidden'); // Show by removing the hidden attribute
            panel.style.height = 'auto'; // or set to the original height, e.g., '300px'
            contentArea.classList.replace("lg:col-span-12", "lg:col-span-8");
            this.toggleButtonInsideTarget.style.display = 'block';
            this.toggleButtonOutsideTarget.style.display = 'none';
            this.makeCalendarSticky();
        }

        this.isSidebarVisible = !this.isSidebarVisible;

        const event = new CustomEvent("sidebarToggled", {
            detail: { isVisible: this.isSidebarVisible },
            bubbles: true
        });
        this.element.dispatchEvent(event);
    }

    makeCalendarSticky() {
        this.contentAreaTarget.classList.add("lg:sticky");
    }

    makeCalendarStatic() {
        this.contentAreaTarget.classList.remove("lg:sticky");
    }
}

import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js/auto"

export default class extends Controller {
  static values = { url: String }
  static targets = ["canvas"]

  connect() {
    this.fetchData()
        .then(() => {
          this.initializeChart()
        })
  }

  initializeChart() {
    const currentYear = new Date().getFullYear();
    Chart.defaults.backgroundColor = '#604638';
    Chart.defaults.borderColor = 'transparent';
    Chart.defaults.color = '#604638';
    Chart.defaults.font.size = 26;

    let width, height, gradient;
    function getGradient(ctx, chartArea) {
      const chartWidth = chartArea.right - chartArea.left;
      const chartHeight = chartArea.bottom - chartArea.top;
      if (!gradient || width !== chartWidth || height !== chartHeight) {
        // Create the gradient because this is either the first render
        // or the size of the chart has changed
        width = chartWidth;
        height = chartHeight;
        gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
        gradient.addColorStop(0, '#604638');
        gradient.addColorStop(1, 'transparent');
      }

      return gradient;
    }

    this.chart = new Chart(this.canvasTarget.getContext("2d"), {
      type: "line",
      data: this.chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: false,
              text: 'Month'
            },
            ticks: {
              font: {
                size: 14
              }
            },
            grid: {
              color: function(context) {
                const chart = context.chart;
                const {ctx, chartArea} = chart;

                if (!chartArea) {
                  // This case happens on initial chart load
                  return;
                }
                return getGradient(ctx, chartArea);
              },
              drawBorder: false,
              drawOnChartArea: true,  // This will draw vertical grid lines
              drawTicks: false
            }
          },
          y: {
            display: false,
            beginAtZero: true,
            title: {
              display: false,
              text: 'Total Expenses'
            },
            grid: {
              drawBorder: false,
              drawOnChartArea: false,  // This will prevent drawing horizontal grid lines
              drawTicks: false
            }
          }
        },
        plugins: {
          legend: { display: false },
          title: {
            display: true,
            text: `Spending`,
            align: 'start',
            font: {
              family: 'Lato',
              weight: '500',
            },
            padding: {
              bottom: 5
            }
          },
          subtitle: {
            display: true,
            align: 'start',
            text: 'Overall',
            color: '#000',
            font: {
              size: 16,
              family: 'Inter',
              weight: '',
              style: ''
            },
            padding: {
              bottom: 30
            }
          },
          tooltip: {
            titleFont: {
              size: 16,
              weight: '400'
            },
            bodyFont: {
              size: 16
            },
            displayColors: false,
            usePointStyle: true,
            callbacks: {
              label: function(context) {
                let label = '';

                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.y);
                }
                return label;
              },
              labelTextColor: function(context) {
                return '#C99C73';
              },
              labelPointStyle: function(context) {
                return {
                  pointStyle: false,
                };
              }
            },
            enabled: true,
            backgroundColor: 'rgba(255, 255, 255, 1)', // Black background with 0.8 opacity
            titleColor: '#604638',
            bodyColor: '#604638',
            borderColor: 'white', // if you want a border
            borderWidth: 1,
            // ... other tooltip options ...
          },
        }
      }
    });
  }

  fetchData() {
    let url = this.urlValue

    return fetch(url, {
      method: "GET",
      headers: { "Content-Type": "application/json" }
    })
        .then(response => response.json())
        .then((json) => {
          const months = json.map(entry => entry.month);
          const totals = json.map(entry => entry.total);

          this.chartData = {
            labels: months,
            datasets: [{
              label: 'Expenses',
              backgroundColor: 'rgba(255, 255, 255, 1)',
              borderColor: '#FFFFFF',
              pointStyle: 'circle',
              pointRadius: 8,
              pointHoverRadius: 10,
              data: totals,
              fill: false,
              tension: 0.4
            }]
          }
        })
  }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    connect() {
        this.moveFlatpickrCalendars();
        // Listen for Turbo page load events
        document.addEventListener('turbo:load', this.moveFlatpickrCalendars.bind(this));
    }

    disconnect() {
        // Remove the Turbo event listener when the controller is disconnected
        document.removeEventListener('turbo:load', this.moveFlatpickrCalendars.bind(this));
    }

    moveFlatpickrCalendars() {
        setTimeout(() => {
            const flatpickrCalendars = Array.from(document.querySelectorAll('.flatpickr-calendar'));
            const containers = Array.from(document.querySelectorAll('#cycle-fp-container, #journal-fp-container, #calendar-fp-container-1, #calendar-fp-container-2'));

            if (flatpickrCalendars.length === 1 && containers.length === 1) {
                this.appendCalendarToContainer(flatpickrCalendars[0], containers[0]);
            } else if (flatpickrCalendars.length > 1 && containers.length > 1) {
                flatpickrCalendars.forEach((calendar, index) => {
                    if (containers[index]) {
                        this.appendCalendarToContainer(calendar, containers[index]);
                    }
                });
            }

            this.element.dispatchEvent(new CustomEvent("flatpickrMover:connected", { bubbles: true }));
        }, 100);
    }

    appendCalendarToContainer(calendar, container) {
        console.log(`Appending .flatpickr-calendar to ${container.id}`);

        requestAnimationFrame(() => {
            const containerWidth = container.offsetWidth;
            const calendarWidth = calendar.offsetWidth;
            const leftOffset = (containerWidth - calendarWidth) / 2;

            calendar.style.position = 'absolute';
            calendar.style.top = '100%'; // Position below the input
            calendar.style.left = `${leftOffset}px`; // Center horizontally
        });

        // Observe changes to the style attribute
        let observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
                    requestAnimationFrame(() => {
                        const containerWidth = container.offsetWidth;
                        const calendarWidth = calendar.offsetWidth;
                        const leftOffset = (containerWidth - calendarWidth) / 2;

                        calendar.style.top = '100%';
                        calendar.style.left = `${leftOffset}px`;
                    });
                }
            });
        });

        observer.observe(calendar, {
            attributes: true,
            attributeFilter: ['style']
        });

        // Append the .flatpickr-calendar element to the container
        container.appendChild(calendar);
    }

    // appendCalendarToContainer(calendar, container) {
    //     console.log(`Appending .flatpickr-calendar to ${container.id}`);
    //
    //     // Modify the top and left properties to be 'auto'
    //     calendar.style.top = 'auto';
    //     calendar.style.left = 'auto';
    //
    //     // Observe changes to the style attribute
    //     let observer = new MutationObserver(mutations => {
    //         mutations.forEach(mutation => {
    //             if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
    //                 calendar.style.top = 'auto';
    //                 calendar.style.left = 'auto';
    //             }
    //         });
    //     });
    //
    //     observer.observe(calendar, {
    //         attributes: true,
    //         attributeFilter: ['style']
    //     });
    //
    //     // Append the .flatpickr-calendar element to the container
    //     container.appendChild(calendar);
    // }
}

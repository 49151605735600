import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["textarea"];

    connect() {
        this.adjustHeight();
    }

    adjustHeight() {
        this.textareaTargets.forEach(textarea => {
            textarea.style.height = '50px';
            textarea.style.height = textarea.scrollHeight + 'px';
        });
    }

    adjust(event) {
        event.target.style.height = '50px';
        event.target.style.height = event.target.scrollHeight + 'px';
    }
}
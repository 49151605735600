import { Application } from "@hotwired/stimulus"
import AccordionController from '@kanety/stimulus-accordion'

const application = Application.start()

// Configure Stimulus development experience
application.debug    = false
window.Stimulus      = application

export { application }

application.register('accordion', AccordionController);




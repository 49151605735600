import { Modal } from "tailwindcss-stimulus-components"

export default class CycleModal extends Modal {

    connect() {
        super.connect();
    }
    // triggerResize() {
    //     const calendarController = this.application.getControllerForElementAndIdentifier(
    //         document.querySelector("#set-cycle-container"), // The element where the calendar controller is attached
    //         "set-cycle-calendar" // The identifier of the calendar controller
    //     );
    //
    //     if (calendarController) {
    //         calendarController.resizeCalendar();
    //     }
    // }
}
import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

export default class extends Controller {
    static targets = [ "window" ];
    static values = { selectedDate: String }
    connect() {
        const timeZone = this.data.get("timeZone")
        const selectedDate = this.selectedDateValue

        this.calendar = new Calendar(this.windowTarget, {
            plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin ],
            selectable: true,
            timeZone: timeZone,
            initialDate: selectedDate,
            eventLongPressDelay: 0,
            selectLongPressDelay: 0,
            eventSources: ['/cycles.json'],
            initialView: 'dayGridMonth',
            titleFormat: { month: 'short', year: 'numeric' },
            headerToolbar: {
                left: 'prev',
                center: 'title',
                right: 'next'
            },
            height: 'auto',
            views: {
                dayGridMonth: { // for the month view
                    dayHeaderFormat: { weekday: 'narrow' },
                    titleFormat: { month: 'short' },
                }
            },
            eventDidMount: function(info) {
                const today = new Date();
                today.setHours(0, 0, 0, 0); // Normalize to start of the day

                // Check if the event meets your custom criteria and the date condition
                if (info.event.extendedProps.customColorChange && info.event.start > today) {
                    // Change color of the event dot
                    const dotEl = info.el.querySelector('.fc-daygrid-event-dot');
                    if (dotEl) {
                        dotEl.style.borderColor = '#FFE6E4';
                    }
                }

                if (info.event.extendedProps.customHideTime) {
                    // Hide the time element
                    if (info.el.querySelector('.fc-event-time')) {
                        info.el.querySelector('.fc-event-time').style.display = 'none';
                    }
                }

                // Select the title element within the event
                const titleEl = info.el.querySelector('.fc-event-title');
                if (titleEl) {
                    titleEl.style.display = 'none'; // Hide the title element
                }
            },
            eventClick: function(info) {
            },
            select: (info) => {
                const today = new Date();
                const selectedDate = new Date(info.startStr);

                // Check if the selected month and year are the same as or before the current month and year
                if (selectedDate.getFullYear() < today.getFullYear() ||
                    (selectedDate.getFullYear() === today.getFullYear() && selectedDate.getMonth() <= today.getMonth())) {

                    // Fetch the updated content for the Turbo Frame
                    fetch(`/cycles?selected_date=${selectedDate.toISOString().split('T')[0]}`, {
                        headers: {
                            'Accept': 'text/vnd.turbo-stream.html',
                            'X-CSRF-Token': this.getCSRFToken(),
                        },
                    })
                        .then(r => r.text())
                        .then(html => Turbo.renderStreamMessage(html))
                }
            },
        });
        this.calendar.render();


        // Highlight the selected date if it exists
        if (selectedDate) {
            this.highlightSelectedDate(selectedDate);
        }
    }

    disconnect() {
    }

    highlightSelectedDate(dateStr) {
        this.calendar.addEvent({
            start: dateStr,
            display: 'background',
            color: "#C99B72",
        });
    }

    getCSRFToken() {
        // Function to get the CSRF token from meta tags
        return document.querySelector("[name='csrf-token']").getAttribute("content");
    }


    refresh(e) {
        if (e.detail.success) {
            this.calendar.refetchEvents();
        }
    }

    reset() {
        this.calendar.refetchEvents();
    }
}
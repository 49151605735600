import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        this.scrollToBottom();
        this.observeContentChanges();
    }

    scrollToBottom() {
        this.element.scrollTop = this.element.scrollHeight;
    }

    observeContentChanges() {
        const observer = new MutationObserver(() => {
            this.scrollToBottom();
        });

        observer.observe(this.element, { childList: true, subtree: true });
    }
}
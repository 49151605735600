import consumer from "./consumer"

consumer.subscriptions.create("SendNotificationChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const icon = data.icon
    const tab = data.tab

    window.TurboNativeBridge.setNotificationCount(icon, tab);
  }
});

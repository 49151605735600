import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

export default class extends Controller {
    static targets = [ "modalWindowOne", "modalWindowTwo", "modalWindowThree" ];
    static values = { selectedDate: String }

    timeoutID = null;

    selectedDate = this.selectedDateValue
    selectedDates = [];
    connect() {

        if (this.hasModalWindowOneTarget) {
            this.calendarOne = this.initializeCalendar(this.modalWindowOneTarget, "calendarOneOptions");
            this.calendarOne.on('datesSet', (dateInfo) => {
                this.insertCustomMessage(this.modalWindowOneTarget);
            });
            this.insertCustomMessage(this.modalWindowOneTarget);
        }
        if (this.hasModalWindowTwoTarget) {
            this.calendarTwo = this.initializeCalendar(this.modalWindowTwoTarget, "calendarThreeOptions");
            if (this.selectedDate) {
                this.highlightSelectedDate(this.selectedDate, this.calendarTwo);
            }
            this.calendarTwo.on('datesSet', (dateInfo) => {
                this.insertCustomMessage(this.modalWindowTwoTarget);
            });
            this.insertCustomMessage(this.modalWindowTwoTarget);
        }
        if (this.hasModalWindowThreeTarget) {
            this.calendarThree = this.initializeCalendar(this.modalWindowThreeTarget, "calendarThreeOptions");
            if (this.selectedDate) {
                this.highlightSelectedDate(this.selectedDate, this.calendarThree);
            }
            this.calendarThree.on('datesSet', (dateInfo) => {
                this.insertCustomMessage(this.modalWindowThreeTarget);
            });
            this.insertCustomMessage(this.modalWindowThreeTarget);
        }
    }

    initializeCalendar(targetElement, optionsMethod) {
        const calendarOptions = this[optionsMethod]();
        const calendar = new Calendar(targetElement, calendarOptions);
        calendar.render();
        return calendar;
    }

    calendarOneOptions() {
        const timeZone = this.data.get("timeZone")
        // Get today's date and the last date of the current month
        const today = new Date();
        // Calculate the first day of the current month, one year ago
        const firstDayOfLastYearMonth = new Date(today.getFullYear() - 1, today.getMonth(), 1);

        const firstDayOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);

        return {
            plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin ],
            selectable: true,
            timeZone: timeZone,
            eventLongPressDelay: 0,
            selectLongPressDelay: 0,
            eventSources: ['/cycles.json'],
            datesSet: (dateInfo) => {
                this.loadEventsForMonth(dateInfo.startStr, dateInfo.endStr);
                console.log("datasets")
            },
            initialView: 'dayGridMonth',
            titleFormat: { month: 'short', year: 'numeric' },
            headerToolbar: {
                left: 'prev',
                    center: 'title',
                    right: 'next'
            },
            views: {
                dayGridMonth: { // for the month view
                    dayHeaderFormat: { weekday: 'narrow' },
                    titleFormat: { month: 'short' },
                }
            },
            validRange: {
                start: firstDayOfLastYearMonth.toISOString().split('T')[0], // Format as 'YYYY-MM-DD'
                end: firstDayOfNextMonth.toISOString().split('T')[0] // Format as 'YYYY-MM-DD'
            },
            eventDidMount: function(info) {
                const today = new Date();
                today.setHours(0, 0, 0, 0); // Normalize to start of the day

                // Check if the event meets your custom criteria and the date condition
                if (info.event.extendedProps.customColorChange && info.event.start > today) {
                    // Change color of the event dot
                    const dotEl = info.el.querySelector('.fc-daygrid-event-dot');
                    if (dotEl) {
                        dotEl.style.borderColor = '#FFE6E4';
                    }
                }

                if (info.event.extendedProps.customHideTime) {
                    // Hide the time element
                    if (info.el.querySelector('.fc-event-time')) {
                        info.el.querySelector('.fc-event-time').style.display = 'none';
                    }
                }

                // Select the title element within the event
                const titleEl = info.el.querySelector('.fc-event-title');
                if (titleEl) {
                    titleEl.style.display = 'none'; // Hide the title element
                }
            },
            eventClick: function(info) {
            },
            select: (info) => {
                this.startDate = info.startStr;
                this.endDate = this.adjustEndDate(info.endStr);
                console.log(this.startDate, this.endDate)
            },
            dateClick: (info) => {
                const clickedDate = info.dateStr;
                const dateIndex = this.selectedDates.indexOf(clickedDate);

                if (dateIndex > -1) {
                    this.selectedDates.splice(dateIndex, 1); // Remove the date if already selected
                } else {
                    this.selectedDates.push(clickedDate); // Add the date if not selected
                }

                this.updateCalendarHighlights(this.calendarOne); // Function to update calendar highlights
            },
            loading: (isLoading) => {
                if (isLoading) {
                    // Show loading indicator
                    this.showLoadingIndicator();
                } else {
                    // Hide loading indicator
                    this.hideLoadingIndicator();
                }
            },
        };
    }

    calendarThreeOptions() {
        const timeZone = this.data.get("timeZone")

        // Get today's date and the last date of the current month
        const today = new Date();
        // Calculate the first day of the current month, one year ago
        const firstDayOfLastYearMonth = new Date(today.getFullYear() - 1, today.getMonth(), 1);

        const firstDayOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);

        return {
            plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin ],
            selectable: true,
            timeZone: timeZone,
            datesSet: (dateInfo) => {
                this.loadEventsForMonth(dateInfo.startStr, dateInfo.endStr);
            },
            initialDate: this.selectedDate,
            eventLongPressDelay: 0,
            selectLongPressDelay: 200,
            eventSources: ['/cycles.json'],
            initialView: 'dayGridMonth',
            titleFormat: { month: 'short', year: 'numeric' },
            headerToolbar: {
                left: 'prev',
                center: 'title',
                right: 'next'
            },
            views: {
                dayGridMonth: { // for the month view
                    dayHeaderFormat: { weekday: 'narrow' },
                    titleFormat: { month: 'short' },
                }
            },
            validRange: {
                start: firstDayOfLastYearMonth.toISOString().split('T')[0], // Format as 'YYYY-MM-DD'
                end: firstDayOfNextMonth.toISOString().split('T')[0] // Format as 'YYYY-MM-DD'
            },
            eventDidMount: function(info) {
                const today = new Date();
                today.setHours(0, 0, 0, 0); // Normalize to start of the day

                // Check if the event meets your custom criteria and the date condition
                if (info.event.extendedProps.customColorChange && info.event.start > today) {
                    // Change color of the event dot
                    const dotEl = info.el.querySelector('.fc-daygrid-event-dot');
                    if (dotEl) {
                        dotEl.style.borderColor = '#FFE6E4';
                    }
                }

                if (info.event.extendedProps.customHideTime) {
                    // Hide the time element
                    if (info.el.querySelector('.fc-event-time')) {
                        info.el.querySelector('.fc-event-time').style.display = 'none';
                    }
                }

                // Select the title element within the event
                const titleEl = info.el.querySelector('.fc-event-title');
                if (titleEl) {
                    titleEl.style.display = 'none'; // Hide the title element
                }
            },
            eventClick: function(info) {
            },
            select: (info) => {
            },
            dateClick: (info) => {
                const clickedDate = info.dateStr;
                const dateIndex = this.selectedDates.indexOf(clickedDate);

                if (dateIndex > -1) {
                    this.selectedDates.splice(dateIndex, 1); // Remove the date if already selected
                } else {
                    this.selectedDates.push(clickedDate); // Add the date if not selected
                }

                if (this.hasModalWindowTwoTarget) {
                    this.updateCalendarHighlights(this.calendarTwo)
                }

                if (this.hasModalWindowThreeTarget) {
                    this.updateCalendarHighlights(this.calendarThree)
                } // Function to update calendar highlights
            },
            loading: (isLoading) => {
                if (isLoading && this.hasModalWindowTwoTarget) {
                    // Show loading indicator
                    this.showLoadingIndicator(1);
                } else {
                    // Hide loading indicator
                    this.hideLoadingIndicator(1);
                }

                if (isLoading && this.hasModalWindowThreeTarget) {
                    // Show loading indicator
                    this.showLoadingIndicator(2);
                } else {
                    // Hide loading indicator
                    this.hideLoadingIndicator(2);
                }
            },
        };
    }

    showLoadingIndicator(index= 0) {
        document.getElementsByClassName('loadingIndicator')[index].style.display = ''
    }

    hideLoadingIndicator(index= 0) {
        document.getElementsByClassName('loadingIndicator')[index].style.display = 'none';
    }

    insertCustomMessage(target) {
        // Get the text from the calendar's title element
        const calendarTitle = target.querySelector('.fc-toolbar-title').textContent.trim();

        // Extract the short month name from the title
        const viewedMonth = calendarTitle; // assuming title is just the short month name like "Nov"

        // Convert the short month names to numerical values
        const shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const viewedMonthNumber = shortMonthNames.indexOf(viewedMonth);
        const currentMonthNumber = (new Date()).getMonth(); // January = 0, December = 11

        if (viewedMonthNumber < currentMonthNumber) {
            this.showCustomMessage(target);
        } else {
            this.hideCustomMessage(target);
        }
    }

    showCustomMessage(target) {
        // Create a new element for the custom message if it doesn't already exist
        let messageElement = target.querySelector('.custom-calendar-message');
        if (!messageElement) {
            messageElement = document.createElement('div');
            messageElement.className = 'custom-calendar-message';
            target.querySelector('.fc-toolbar-title').insertAdjacentElement('afterend', messageElement);
        }
        messageElement.textContent = 'Updating dates in past months will reset future dates.';
        messageElement.style.display = ''; // Make sure the message is visible
    }

    hideCustomMessage(target) {
        // Hide the custom message
        const existingMessage = target.querySelector('.custom-calendar-message');
        if (existingMessage) {
            existingMessage.style.display = 'none';
        }
    }

    loadEventsForMonth(startStr, endStr) {
        const now = new Date();

        // Get the last day of the current month
        const lastDayOfCurrentMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

        // Parse startStr and endStr into Date objects
        let startDate = new Date(startStr);
        let endDate = new Date(endStr);

        // Check if startStr and endStr span different months
        if (startDate.getMonth() !== endDate.getMonth() || startDate.getFullYear() !== endDate.getFullYear()) {
            // Check if endStr is the last day of the current month and startStr is in the previous month
            if (endDate.toISOString().split('T')[0] === lastDayOfCurrentMonth.toISOString().split('T')[0] && startDate.getMonth() !== now.getMonth()) {
                startDate = new Date(now.getFullYear(), now.getMonth(), 1);
            } else if (endDate.getMonth() - startDate.getMonth() > 1 || (endDate.getMonth() === 0 && startDate.getMonth() === 11)) {
                // If there's a month in between, adjust startStr and endStr to that month
                startDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1);
                endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
            } else {
                // If endStr is in the next month, adjust it to the end of the startStr month
                endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
            }
        }

        // Format adjusted dates back to string for comparison
        const adjustedStartStr = startDate.toISOString().split('T')[0];
        const adjustedEndStr = endDate.toISOString().split('T')[0];

        console.log(adjustedStartStr, adjustedEndStr)

        // Fetch all events and then process them
        fetch(`/cycles.json`)
            .then(response => response.json())
            .then(events => {
                this.selectedDates = events.map(event => {
                    try {
                        const date = new Date(event.start);
                        if (!isNaN(date)) {
                            const dateStr = date.toISOString().split('T')[0];
                            if (dateStr >= adjustedStartStr && dateStr <= adjustedEndStr) {
                                return dateStr;
                            }
                        }
                        return null;
                    } catch (error) {
                        console.error('Error parsing date:', event.start, error);
                        return null;
                    }
                }).filter(date => date !== null);
                if (this.hasModalWindowOneTarget) {
                    this.updateCalendarHighlights(this.calendarOne)
                }

                if (this.hasModalWindowTwoTarget) {
                    this.updateCalendarHighlights(this.calendarTwo)
                }

                if (this.hasModalWindowThreeTarget) {
                    this.updateCalendarHighlights(this.calendarThree)
                }

            })
            .catch(error => console.error('Error loading events:', error));
    }

    updateCalendarHighlights(calendar) {
        // Clear all existing background events
        calendar.getEvents().forEach(event => {
            if (event.display === 'background' && !event.extendedProps.show) {
                event.remove();
            }
        });

        // Add background event for each selected date
        this.selectedDates.forEach(dateStr => {
            calendar.addEvent({
                start: dateStr,
                display: 'background',
                color: '#ff9f89', // Customize the highlight color
            });
        });
    }

    highlightSelectedDate(dateStr, calendar) {
        calendar.addEvent({
            start: dateStr,
            display: 'background',
            color: "#C99B72",
            show: true
        });
    }

    adjustEndDate(endStr) {
        let endDate = new Date(endStr);
        let startDate = new Date(this.startDate);

        // Check if DST change occurs within the selected range
        if (this.isDSTChange(startDate, endDate)) {
            endDate.setHours(endDate.getHours() - 1); // Adjust for the extra hour in the day
        } else {
            endDate.setDate(endDate.getDate() - 1); // Standard adjustment
        }

        return endDate.toISOString().split('T')[0];
    }

    // Check if DST change occurs between two dates
    isDSTChange(start, end) {
        return start.getTimezoneOffset() !== end.getTimezoneOffset();
    }

    resizeCalendar() {
        this.timeoutID = setTimeout(() => {
            console.log("Resizing calendars now");
            if (this.calendarOne) {
                this.calendarOne.updateSize();
            }
            if (this.calendarTwo) {
                this.calendarTwo.updateSize();
            }
            if (this.calendarThree) {
                this.calendarThree.updateSize();
            }
        }, 100);
    }

    onClickSubmitButton() {
        // Handle the button click event
        if (this.selectedDates) {
            console.log(this.selectedDates)
            this.onSubmitDates(this.selectedDates, this.selectedDate);
        }
    }
    onSubmitDates(selectedDates, selectedDate) {

        // Remove duplicates by converting to a Set, then back to an array
        const uniqueDates = Array.from(new Set(selectedDates));

        // Join unique dates into a string
        const dateRangeString = uniqueDates.join(', ');

        const data = { cycle: { date_range: dateRangeString, selected_date: selectedDate } };

        // Use fetch to send an AJAX request
        fetch('/cycles', {
            method: 'POST',
            headers: {
                'Accept': 'text/vnd.turbo-stream.html, text/html, application/xhtml+xml', // Accept Turbo Stream
                'Content-Type': 'application/json',
                'X-CSRF-Token': this.getCSRFToken(), // Include CSRF token for Rails
            },
            body: JSON.stringify(data),
        })
            .then(r => r.text())
            .then(html => Turbo.renderStreamMessage(html))
            .then(() => {
                if (this.hasModalWindowOneTarget) {
                    this.calendarOne.refetchEvents();
                    this.calendarOne.today();
                }
            })
    }

    getCSRFToken() {
        // Function to get the CSRF token from meta tags
        return document.querySelector("[name='csrf-token']").getAttribute("content");
    }

    clearResizeTimeout() {
        if (this.timeoutID) {
            clearTimeout(this.timeoutID);
        }
    }

    disconnect() {
        this.clearResizeTimeout();
    }

    resetCalendarOne() {
        if (this.hasModalWindowOneTarget) {
            this.calendarOne.today();
        }
    }
}
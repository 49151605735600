import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {

  connect() {
  }

  async toggle(e) {
    e.preventDefault()
    const id = e.target.dataset.id

    const request = new FetchRequest(
        'post',
        `/caretaker_permissions/${id}/toggle`,
        {
          body: JSON.stringify({ enabled: e.target.checked }),
          responseKind: 'turbo-stream'
        })
    await request.perform()
    window.dispatchEvent(event);
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["button", "content"];

    toggle(event) {
        event.preventDefault();
        this.contentTargets.forEach((content, index) => {
            if (this.buttonTargets[index] === event.currentTarget) {
                content.classList.toggle('hidden');
            } else {
                content.classList.add('hidden');
            }
        });
    }
}

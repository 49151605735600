import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
    static targets = ["input", "recordButton", "stopButton", "controls", "save"]

    connect() {
        // Initial setup can be done here, if needed
    }

    async record() {
        this.chunks = []
        const stream = await this.stream();
        this._stream = stream;
        this.flipButtons();
        const recorder = await this.recorder();
        recorder.start(1000); // Start recording with timeslice of 1000ms
        alert("Audio recording will start");
        this.saveTarget.setAttribute("disabled", "true");
    }

    stop() {
        this.flipButtons();
        this.recorder().then(recorder => recorder.stop());
        this.controlsTarget.classList.toggle('hide-btn');
        alert("Audio recording will stop");
        this.saveTarget.removeAttribute('disabled');
    }

    stream() {
        if (this._stream === undefined) {
            return navigator.mediaDevices.getUserMedia({ audio: true });
        }
        return Promise.resolve(this._stream);
    }

    async recorder() {
        if (this._recorder === undefined) {
            let that = this;
            let stream = await this.stream(); // Ensure the stream is resolved before proceeding
            this._recorder = new MediaRecorder(stream, { mimeType: 'audio/mp4' }); // Use a mimeType supported by Safari
            this._recorder.ondataavailable = function(e) {
                that.chunks.push(e.data);
            };
            this._recorder.onstop = async function() {
                const file = new File(that.chunks, 'audio.m4a', { type: 'audio/mp4' }); // Use audio/mp4 format
                const upload = new DirectUpload(file, that.url());
                upload.create((error, blob) => {
                    if (error) {
                        // Handle the error
                    } else {
                        // On successful upload, attach the blob id to the form
                        that.hiddenInput = document.createElement('input');
                        that.hiddenInput.type = 'hidden';
                        that.hiddenInput.name = that.inputTarget.name;
                        that.hiddenInput.value = blob.signed_id;
                        that.inputTarget.parentNode.insertBefore(that.hiddenInput, that.inputTarget.nextSibling);
                    }
                });
            };
        }
        return this._recorder;
    }

    flipButtons() {
        this.recordButtonTarget.classList.toggle('hide-btn');
        this.stopButtonTarget.classList.toggle('hide-btn');
    }

    url() {
        return this.inputTarget.getAttribute('data-direct-upload-url');
    }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["mobileSidebar", "backdrop", "desktopSidebar"];

    connect() {
        // This method is called automatically when the controller is connected to the DOM
    }

    toggleMobileSidebar() {
        // this.mobileSidebarTarget.classList.toggle("translate-x-0");
        // this.mobileSidebarTarget.classList.toggle("-translate-x-full");
        this.mobileSidebarTarget.classList.toggle("hidden");
        // this.backdropTarget.classList.toggle("opacity-0");
        // this.backdropTarget.classList.toggle("opacity-100");
        console.log("click")
    }

    closeMobileSidebar() {
        // this.mobileSidebarTarget.classList.remove("translate-x-0");
        // this.mobileSidebarTarget.classList.add("-translate-x-full");
        // this.backdropTarget.classList.remove("opacity-100");
        // this.backdropTarget.classList.add("opacity-0");
        this.mobileSidebarTarget.classList.toggle("hidden");
    }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    // static values = { cycleNoteId: Number }

    connect() {
    }

    toggleSymptom(event) {
        const cycleNoteId = this.data.get("cycleNoteId")
        const symptomId = event.target.value;
        fetch(`/cycle_notes/${cycleNoteId}/toggle_symptom`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': this.getCSRFToken(),
            },
            body: JSON.stringify({ symptom_id: symptomId }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text();
            })
            .then(html => {
            // Turbo.visit(window.location.toString(), { action: 'replace' });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    getCSRFToken() {
        // Function to get the CSRF token from meta tags
        return document.querySelector("[name='csrf-token']").getAttribute("content");
    }
}

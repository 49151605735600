import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { DateTime } from "luxon";

export default class extends Controller {
    static targets = [ "eventPopup", "window" ];
    connect() {
        const timeZone = this.data.get("timeZone")
        let eventOverlay = this.eventPopupTarget;

        this.calendar = new Calendar(this.windowTarget, {
            plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin ],
            selectable: true,
            timeZone: timeZone,
            eventLongPressDelay: 0,
            selectLongPressDelay:0,
            height: 'auto',
            eventSources: ['/events.json','/journals.json', '/expenses.json', '/cycles.json'],
            initialView: 'dayGridMonth',
            titleFormat: { month: 'short', year: 'numeric' },
                customButtons: {
                    filter: {
                        text: 'filter',
                        click: function(info) {
                            eventOverlay.src = '/event_types'
                        }
                    },
                    add: {
                        text: 'add event',
                        click: function(info) {
                            eventOverlay.src = '/events/new'
                        }
                    }
            },
            headerToolbar: {
                left: 'prev,today,filter',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek,next'
            },
            footerToolbar: {
                left: 'add',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            },
            eventDidMount: function(info) {
                const today = new Date();
                today.setHours(0, 0, 0, 0); // Normalize to start of the day

                // Check if the event meets your custom criteria and the date condition
                if (info.event.extendedProps.customColorChange && info.event.start > today) {
                    // Change color of the event dot
                    const dotEl = info.el.querySelector('.fc-daygrid-event-dot');
                    if (dotEl) {
                        dotEl.style.borderColor = '#FFE6E4';
                    }
                }

                if (info.event.extendedProps.customHideTime) {
                    // Hide the time element
                    if (info.el.querySelector('.fc-event-time')) {
                        info.el.querySelector('.fc-event-time').style.display = 'none';
                    }
                }

                // Select the title element within the event
                const titleEl = info.el.querySelector('.fc-event-title');
                if (titleEl && titleEl.textContent === "cycle") {
                    titleEl.style.display = 'none'; // Hide the cycle title element
                }
            },
            eventClick: function(info) {
                if (info.event.extendedProps.category === "journal") {
                    console.log(info.event)
                    eventOverlay.src = '/journals/calendar/' + info.event.id +
                        '?virtual_start_time=' + info.event.startStr + '&virtual_end_time=' + info.event.endStr;
                } else if (info.event.extendedProps.category === "expense") {
                    console.log(info.event)
                    eventOverlay.src = '/expenses/calendar/' + info.event.id +
                        '?virtual_start_time=' + info.event.startStr + '&virtual_end_time=' + info.event.endStr;
                } else if (info.event.extendedProps.category === "period_cycle") {
                    console.log(info.event)
                    eventOverlay.src = '/cycles/calendar/' + info.event.id +
                        '?virtual_start_time=' + info.event.startStr + '&virtual_end_time=' + info.event.endStr;
                } else {
                    console.log(info.event)
                    eventOverlay.src = '/events/' + info.event.id +
                        '?virtual_start_time=' + info.event.startStr + '&virtual_end_time=' + info.event.endStr;
                }
            },
            select: function(info) {
                function format_default_times(start_time) {
                    let datePlusTime;
                    if (info.view.type == "dayGridMonth") {
                        const date = DateTime.fromISO(start_time).toFormat('yyyy-MM-dd').toLocaleString()
                        const time = DateTime.now().toISOTime()
                        datePlusTime = DateTime.fromISO(`${date}T${time}`)
                    } else {
                        if (info.allDay == true) {
                            const date = DateTime.fromISO(start_time).toFormat('yyyy-MM-dd').toLocaleString()
                            const time = DateTime.now().toISOTime()
                            datePlusTime = DateTime.fromISO(`${date}T${time}`)
                        } else {
                            datePlusTime = DateTime.fromISO(start_time)
                        }
                    }
                    const default_start_utc = datePlusTime.toUTC().toISO()
                    const default_end_utc = datePlusTime.toUTC().plus({hours: 1}).toISO()
                    return [default_start_utc, default_end_utc]
                }
                const [default_start, default_end] = format_default_times(info.startStr)
                eventOverlay.src = '/events/new?start_time=' + default_start + '&end_time=' + default_end;
            },
            loading: (isLoading) => {
                if (isLoading) {
                    // Show loading indicator
                    this.showLoadingIndicator();
                } else {
                    // Hide loading indicator
                    this.hideLoadingIndicator();
                }
            }
        });
        this.calendar.render();

        this.boundResizeCalendar = this.resizeCalendar.bind(this);
        this.element.addEventListener("sidebarToggled", this.boundResizeCalendar);
    }

    showLoadingIndicator(index= 0) {
        document.getElementsByClassName('loadingIndicator')[index].style.display = ''
    }

    hideLoadingIndicator(index= 0) {
        document.getElementsByClassName('loadingIndicator')[index].style.display = 'none';
    }


    disconnect() {
        this.element.removeEventListener("sidebarToggled", this.boundResizeCalendar);
    }

    refresh(e) {
        if (e.detail.success) {
            this.calendar.refetchEvents();
        }

    }

    reset() {
        this.calendar.refetchEvents();
    }

    resizeCalendar() {
        console.log("RESIZE CALENDAR");
        this.calendar.updateSize();
    }
}
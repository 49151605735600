import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
    static targets = ["progressButton"]

    connect() {
        this.progressButtonTarget.addEventListener("click", this.cancelRun.bind(this))
    }

    disconnect() {
        this.progressButtonTarget.removeEventListener("click", this.cancelRun.bind(this))
    }

    cancelRun(event) {
        event.preventDefault() // Prevent form submission

        const chatId = this.progressButtonTarget.getAttribute("data-chat-id")

        fetch(`/chats/${chatId}/cancel_run`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content")
            },
            body: JSON.stringify({ chat_id: chatId })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json()
            })
            .then(data => {
                if (data.status === "success") {
                    console.log("Run cancelled successfully")
                } else {
                    console.error("Error cancelling run:", data.message)
                }
            })
            .catch(error => {
                console.error("Error:", error)
            })
    }
}
